import { AmplifyUrl, AmplifyUrlSearchParams } from '@aws-amplify/core/internals/utils';
import { RestApiError } from '../errors/RestApiError.mjs';
import { assertValidationError } from '../errors/assertValidatonError.mjs';
import { RestApiValidationErrorCode, validationErrorMap } from '../errors/validation.mjs';

// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
/**
 * Resolve the REST API request URL by:
 * 1. Loading the REST API endpoint from the Amplify configuration with corresponding API name.
 * 2. Appending the path to the endpoint.
 * 3. Merge the query parameters from path and the queryParameter argument which is taken from the public REST API
 *   options.
 * 4. Validating the resulting URL string.
 *
 * @internal
 */
const resolveApiUrl = (amplify, apiName, path, queryParams) => {
  const urlStr = amplify.getConfig()?.API?.REST?.[apiName]?.endpoint;
  assertValidationError(!!urlStr, RestApiValidationErrorCode.InvalidApiName);
  try {
    const url = new AmplifyUrl(urlStr + path);
    if (queryParams) {
      const mergedQueryParams = new AmplifyUrlSearchParams(url.searchParams);
      Object.entries(queryParams).forEach(([key, value]) => {
        mergedQueryParams.set(key, value);
      });
      url.search = new AmplifyUrlSearchParams(mergedQueryParams).toString();
    }
    return url;
  } catch (error) {
    throw new RestApiError({
      name: RestApiValidationErrorCode.InvalidApiName,
      ...validationErrorMap[RestApiValidationErrorCode.InvalidApiName],
      recoverySuggestion: `Please make sure the REST endpoint URL is a valid URL string. Got ${urlStr}`
    });
  }
};
export { resolveApiUrl };
